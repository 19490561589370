import * as React from 'react';
import { Button, AppBar, Toolbar, ButtonGroup } from '@material-ui/core';
// import { Button, Toggle } from '@material-ui/core';
import {_listMapToOptions, _listToOptions, _valToOption} from '../utils/dropdown'
import logo from './../icons/namami-gange-logo.png'
import qci_logo from './../icons/qci.png'
import SelectBar from '../components/SelectBar';
import GroupIcon from '@material-ui/icons/Group';
import AddCommentIcon from '@material-ui/icons/AddComment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { navigate, Link } from 'gatsby';
import Cookies from "js-cookie"

export default function Header({isSelectBar, isTogglePage, page, states, districts, blocks, villages, waterBodies,
   state, district, block, village, waterBody, height,
   setCountryValue, setStateValue, setDistrictValue, setBlockValue, setVillageValue, setWaterBodyValue}) {
    return (
        <div style={{minHeight: height}}>
          <AppBar style={{position: "sticky"}} style={{backgroundColor: 'white'}}>
            <Toolbar style={{display: "block"}}>
                <div  style={{display: "flex", justifyContent: "space-between"}}>
                  <a href="https://nmcg.nic.in/">
                    <img key="namami-gange-logo" src={logo} className="img-responsive" style={{height: "70px"}}/>
                  </a>

                  <Link to="/" style={{textDecoration: "none"}}>
                    <h2 style={{color: "darkslateblue"}}>Census Survey of Water Bodies in Ganga Basin</h2>
                  </Link>

                  <a href="https://www.qcin.org/">
                    <img key="qci-logo" src={qci_logo} className="img-responsive" style={{height: "70px"}}/>
                  </a>
                </div>

                {
                  isSelectBar ?
                  <>
                  <hr />
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <SelectBar value={_valToOption(state)} label="State" options={_listToOptions(states)} disabled={false}
                        handleChange={(e, v) => {
                        if(v?.value){
                          setStateValue(v.value);
                        }
                        else{
                          setCountryValue();
                        }
                        }}
                    />
                    <SelectBar value={_valToOption(district)} label="District" options={_listToOptions(districts)} disabled={!state}
                        handleChange={(e, v) =>{
                          if(v?.value){
                            setDistrictValue(v.value)
                          }
                          else{
                            setStateValue(state);
                          }
                        }}
                    />
                    <SelectBar value={_valToOption(block)} label="Block" options={_listToOptions(blocks)} disabled={!district}
                        handleChange={(e, v) =>
                        {if(v?.value){
                          setBlockValue(v.value)
                        }
                        else{
                          setDistrictValue(district);
                        }
                        }}
                    />
                    <SelectBar value={_valToOption(village)} label="Village" options={_listToOptions(villages)} disabled={!block}
                        handleChange={(e, v) =>
                        {if(v?.value){
                          setVillageValue(v.value)
                        }
                        else{
                          setBlockValue(block);
                        }
                        }}
                    />
                    <SelectBar value={_valToOption(waterBody?.name)} label="Water Body" options={_listMapToOptions(waterBodies, 'name', 'copy_object')} width="280px"  disabled={!village}
                        handleChange={(e, v) =>
                        {if(v?.value){
                          setWaterBodyValue(v.value)
                        }
                        else{
                          setVillageValue(village);
                        }
                        }}
                    />
                    <Button variant="text" color="primary"
                      onClick={() => {
                        navigate("/login")
                      }}
                    >
                      <AccountCircleIcon /> Login
                    </Button>
                  </div>
                  </> :
                  null
                }

                {
                  isTogglePage ?
                  <>
                  <hr />
                  <div style={{display: "flex", justifyContent: "space-between", margin: "10px"}}>
                    <ButtonGroup
                      variant="outlined"
                      aria-label="text alignment"
                    >
                      <Button color="primary" onClick={ (e) => navigate("/app/users") } aria-label="left aligned">
                        <GroupIcon />Users
                      </Button>
                      <Button color="primary" onClick={ (e) => navigate("/app/requests") } aria-label="centered">
                        <AddCommentIcon />Requests
                      </Button>
                    </ButtonGroup>

                    <Button variant="outlined" color="secondary"
                      onClick={() => {
                        navigate("/login")
                        Cookies.remove('access_token_cookie', { path: '/' })
                      }}
                    >
                      <ExitToAppIcon /> Logout
                    </Button>
                  </div>
                  </> :
                  null
                }
                
            </Toolbar>
          </AppBar>
          <Toolbar />
        </ div>
      );
}
