import * as React from 'react';
import { Typography, Link } from '@material-ui/core';


export default function CopyRight() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      <div>
        {'Copyright © '}
        <Link color="#5664d2" href="https://www.qcin.org/">
          QCI
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </div>

      <div>
       {'Developed By '}
        <Link color="#5664d2" href="https://dataorc.in/">
          Dataorc
        </Link>{' '}
      </div>
      
      
    </Typography>
  );
}
