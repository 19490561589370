import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

class Spinner extends React.Component {
  render() {
    return (
      <div className="row prime-loading-spinner">
        <div className="loader-wrap">
          <div className="loader d-flex justify-content-center">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Spinner;
