export var _mapToOptions = function (data, k=null) {
    var options = [];
    for (var d in data) {
      options.push({
        label: (k ? data[d][k]: data[d]),
        value: d
      });
    }

    return options;
};

export var _mapObjToOptions = function (data, valueField, labelField=null) {
  var options = [];
  if (data) {
    if (labelField) {
      options.push({
        label: data[labelField],
        value: data[valueField]
      });
    } else {
      options.push({
        label: data[valueField],
        value: valueField
      });
    }
  }

  return options;
};

export var _listToOptions = function (data) {
  var options = [];
  if (data) {
    data.map(d => options.push({label: d,value: d}));
  }
  return options;
};

export var _listMapToOptions = function (data, labelField, valueField) {
  var options = [];
  if (data) {
    data.map (d => (
      valueField == 'copy_object' ?
        options.push({
          label: d[labelField],
          value: d
        }) : options.push({
          label: d[labelField],
          value: d[valueField]
        })
    ));
  }
  return options;
};

export var _valToOption = function (v) {
  return {
    label: v,
    value: v
  };
};
