import * as React from 'react';
import { MenuItem, FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';


export default function SelectBar({value, label, options, handleChange, width=200, disabled=false}) {
  const renderOption = (o) => {
    return (<MenuItem value={o}>{o}</MenuItem>)
  }

  return (
    <FormControl 
    // sx={{ m: 2, minWidth: width }}
    style={{fullWidth: true, width: width, margin: "10px"}}
    >
        <Autocomplete
          disablePortal
          id="state-select"
          options={options}
          // defaultValue={value}
          disabled={disabled}
          value={value ? value : null}
          onChange={handleChange}
          getOptionLabel={(option) => option.label ? option.label : ''}
          // sx={{ width: 200 }}
          renderInput={(params) => <TextField variant="outlined" {...params} label={label} />}
        />
    </FormControl>
  );
}