/*
 * XHR utility functions
 */
// import userLogout from './authorization.js';

var api_server = "";

export const _queryStringify = function (obj) {
  var qs = [], key;
  for (key in obj) {
    if (obj.hasOwnProperty (key) &&
        typeof obj[key] !== "undefined" &&
        obj[key] !== null) {
      qs.push (encodeURIComponent (key) + "=" + encodeURIComponent (obj [key]));
    }
  }
  return qs.join ("&");
};

export const isSuccess = function (status) {
  return (status >= 200 && status < 300);
};

export const makeXHR = function (obj) {
  var req = new XMLHttpRequest();
  var url = api_server + obj.url;
  var async = (typeof obj.async !== "undefined") ? obj.async : true;
  obj.data = obj.data || {};
  var formParams = _queryStringify (obj.data);

  if (obj.method === "GET") {
    url = url + "?" + formParams;
  }

  req.open (obj.method, url, async);
  //req.setRequestHeader ("Access-Control-Allow-Origin", "*");

  if (obj.method === "POST") {
    req.setRequestHeader ("X-Requested-With", "XMLHttpRequest");
    req.setRequestHeader ("Content-Type", "application/x-www-form-urlencoded");
  }

  if (async) {
    req.onreadystatechange = function() {
      var text = req.responseText;

      if (req.readyState === 4) {
        if (obj.onSuccess &&
            isSuccess (req.status)) {
          var resp = JSON.parse(text);
          obj.onSuccess (obj.ctx, resp, req.status);
        } else if (req.status === 401) {
          // userLogout();
        } else if (!isSuccess (req.status) &&
                   obj.onFailure) {
          obj.onFailure (obj.ctx, text, req.status);
        }
      }
    };
  }

  req.send (formParams);
  return req;
};


export var handleResponse = function(response) {
  return response.text().then(text => {
    if (!response.ok) {
      let error;
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // userLogout();
        return Promise.reject("Session timeout");
        //location.reload(true);
      }
      if (response.status === 500 || response.status === 404) {
        error = "Something went wrong! Please try again after some time";
      } else {
        error = JSON.parse(text)["error"];
      }

      return Promise.reject(error);
    }

    const data = text && JSON.parse(text);
    return data;
  });
};


export const handleLoginResponse = function(response) {
  return response.text().then(text => {
    if (!response.ok) {
      let error;
      if (response.status === 401) {
        return Promise.reject("Invalid username/password");
      }
      if (response.status === 500 || response.status === 404) {
        error = "Something went wrong! Please try again after some time";
      } else {
        error = JSON.parse(text)["error"];
      }

      return Promise.reject(error);
    }

    const data = text && JSON.parse(text);
    return data;
  });
}
